:root{
  --stars:white;
  --star_bg:black;
}
body {
  overflow:hidden;
}
.no-scroll {
  overflow: hidden;
}
.background {
  background-color: black;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  z-index:-1;
}

.g-logo {
  width: 88px;
  height: 88px;
  margin:15%;
  margin-left: 47%;
  margin-top: -5%;
}
.exploreButton{
  margin-left: 13%;
}
.git-header {
  width: 100%;
  height: 29px;
  margin-left: 45%;
  margin-top: -14%;
  opacity: 100px;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #e7edf2;
  
}

.git-content {
  position: absolute;
  z-index: 1;
  width: 18%;
  height: Hug (234px);
  margin-top: 10px;
  margin-left: 37%;
  padding: 52px 60px 52px 60px;
  border: 1.5px;
  gap: 24px;
  border-color: #31363c;
  border-radius: 5px;
  background-color: #1f2123;
  /* margin-bottom: -40% !important; */
}
/* .git-content-two{
  margin-bottom:40%;
} */
.g-text {
  width: 218px;
  height: 25px;
  margin-left: 21%;
  margin-top: 5px;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #e7edf2;
  
}

.g-text-two {
  margin-top: -5%;
  width: 274px;
  height: 46px;
  margin-left: 0px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #596069;
}

.g-auth {
  width: (394px);
  height: (234px);
  top: 423px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 5px 5px 5px 87px;
  border: 1.5px;
  border-color: none;
  gap: 24px;
  background-color: #54a258;
  color: white;
  border-radius: 10px;
}
.auth-button{
  background-color: #54A258;
  color:white;
  width:275px;
  margin-left: 1%;
  height:35px;
  border-radius: 11.5px;
  border-style: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 100;
  line-height: 17px;
  letter-spacing: 0.1px;
}
.auth-button:hover {
  /* background-color: #0056b3; */
  background-color: #3c793f;
}

.auth-button:active {
  background-color: #266a2a;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

.img-404 {
  filter: invert(100%) brightness(120%);
}

a {
  color: #fdcc52;
  transition: all 0.35s;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #fcbd20;
  text-decoration: none;
}

hr {
  max-width: 100px;
  margin: 25px auto 25px auto;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}
hr.full-width {
  max-width: 100%;
  margin: 25px auto;
}

hr.light {
  border-color: white;
}


img.logo {
  height: 50px;
}
header.masthead,
.jumbotron {
  position: relative;
  width: 100%;
  padding-top: 150px;
  color: white;
  background: var(--star_bg);
  /* background: linear-gradient(to right, black, red); */
  background-attachment: fixed;
}
header.masthead .device-container .screen img {
  border-radius: 3px;
}

header.masthead {
  height: min-content;
}
header.not-full-height {
  height: unset;
}
.buttonsIB{
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
.infBtn,.bndBtn{
  width:47% ;
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
  }
  header.not-full-height {
    padding-top: 20vh;
    margin-bottom: 0px;
  }
  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }
  header.masthead .header-content h1 {
    font-size: 45px;
    font-weight: 800;
  }
  header.masthead .device-container {
    max-width: 325px;
  }
}

.hero-buttons {
  cursor: pointer;
  transition: 0.1s ease;
}
.hero-buttons.last {
  border-color: #1adb80 !important;
  background: #1adb80 !important;
  display: block;
  /* width: 250px; */
  text-align: center;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.waveWrapperInner {
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}
.bgTop {
  margin-top: -26%;
  opacity: 0.5;
}
.bgMiddle {
  margin-top: -47%;
  opacity: 0.75;
}
.bgBottom {
  margin-top: -46%;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png');
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png');
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png');
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

header.masthead .container {
  z-index: 3;
 margin-bottom: 10%;
  position: relative;
}

@media (max-width: 1023px) {
  .wave {
    position: absolute;
    left: 0;
    width: 700%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
  }
}
ul.arrow {
  padding: 10px;
}

.hero-text {
  color: #bbc;
}

ul li {
  list-style: none;
  position: relative;
  padding: 0 0 0 20px;
}
ul.arrow li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  height: 10px;
  width: 10px;
  border: 1px solid #f60;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}
ul.burst li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background: #f60;
  width: 10px;
  height: 10px;
  text-align: center;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}


@media (max-width: 768px){
  .background{
    background-color: black;
    color: black;
    height:10% !important;
  }
  .bgg{
    overflow:hidden !important;
  }

header.masthead,.jumbotron {
  position: absolute;
  width: 100%;
  height:100%;
  padding-top: 150px;
  color: white;
  background: var(--star_bg);
  /* background: linear-gradient(to right, black, red); */
  background-attachment: fixed;
}
.g-logo{
  margin-left: 38.5%;
}
.git-header {
  margin-left: 30%;
  margin-top: -7%;
  opacity: 100px;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #e7edf2;
  /* margin-bottom: -30%; */
}
  .git-content{
    margin-left: 4%;
    width:60%;
    margin-bottom: -30%;
  }
  .g-text{
    margin-left: 15%;
  }
  .g-text-two{
    margin-left: -11%;
  }
  .exploreButton{
    margin-left: -10%;
  }
  .waveWrapperInner{
    margin-top: 0%;
  }
  /* .waveWrapper{
    margin-bottom: 30%;
  } */
}
 @media (max-width: 768px){
  header.masthead,.jumbotron {
  position: absolute;
  width: 100%;
  height:100%;
  padding-top: 150px;
  color: white;
  background: var(--star_bg);
  /* background: linear-gradient(to right, black, red); */
  background-attachment: fixed;
}
}