.nav{
    z-index: 9999 !important;
}
.nav-logo{
    margin:20px;
}
/* .nav-dp{
    margin-left: 87%;
    margin-bottom: 1%;
} */
.QuickAccess{
    margin-left: 35%;
    margin-top:-3.5%;
}
.QuickAccess_items{
    padding: 1%;
    font-size: 18px;
    font-weight: 350;
}
.QuickAccess_items:hover{
color:#a8a807;
}
/* .lightDarkToggleSwitch{

} */

/* Light-Dark button */

:root {
    --light: #d8dbe0;
    --dark: #28292c;
    --link: rgb(27, 129, 112);
    --link-hover: rgb(24, 94, 82);
  }
  
  
  .lightDarkToggleSwitch {
    margin-left: 82%;
    margin-top:-3%;
    position: relative;
    width: 200px;
  }
  
  label {
    position: absolute;
    width: 65%;
    height: 55px;
    background-color: var(--dark);
    border-radius: 50px;
    cursor: pointer;
  }
  
  input {
    position: absolute;
    display: none;
  }
  
  .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.6s;
  }
  
  input:checked ~ .slider {
    background-color: var(--light);
  }
  
  .slider::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 7px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: inset 28px -4px 0px 0px var(--light);
    background-color: var(--dark);
    transition: 0.3s;
  }
  
  input:checked ~ .slider::before {
    transform: translateX(72px);
    background-color: var(--dark);
    box-shadow: none;
  }
@media (max-width: 768px){
  .QuickAccess{
    margin-top: 40%;
    margin-left: 0%;
    /* background-color: white; */
  }
}